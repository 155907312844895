import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const Terminals = () => {
    return (
        <Layout>
            <Seo title="Storage" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Storage</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">Coming soon...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Terminals
